import {
    createBaseThemeOptions,
    createUnifiedTheme,
    palettes,
  } from '@backstage/theme';
  
  export const themeKpconsulting = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.dark,
        primary: {
          main: '#F97300',
        },
        secondary: {
          main: '#565a6e',
      },
    },
  }),
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          width: 'auto',
          margin: '20px',
          boxShadow: 'none',
          borderBottom: `4px solid ${theme.palette.primary.main}`,
          background: `${theme.palette.primary.main}`,
        }),
      },
    },
  },
    defaultPageTheme: 'home',
  });